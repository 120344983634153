import {
  SUBSCRIPTION_PLAN_ADVANCED,
  SUBSCRIPTION_PLAN_ELITE,
  SUBSCRIPTION_PLAN_ONBOARDING,
  SUBSCRIPTION_PLAN_PREMIUM
} from '@/constants/subscriptionPlans.js'
import { ROLE_COMPANY_RIDER } from '@/constants/userRoles.js'
import getRoleIsCompanyRole from '@/helpers/getRoleIsCompanyRole.js'
import { SET_PARTIAL_SUBSCRIPTION_DATA, SET_SUBSCRIPTIONS } from '@/store/mutationTypes.js'

const state = () => ({
  subscriptions: []
})

const getters = {
  districtsIsAvailable: (state, getters, rootState, rootGetters) => {
    return true
  },
  companyHasFreePlan: (state, getters, rootState, rootGetters) => {
    const someMainSubscriptionIsActivated = getters.companyHasOnboardingPlan || getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan
    return !state.subscriptions.length || !someMainSubscriptionIsActivated
  },
  companyHasElitePlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_ELITE)
  },
  companyHasOnboardingPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_ONBOARDING)
  },
  companyHasAdvancedPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_ADVANCED)
  },
  companyHasPremiumPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_PREMIUM)
  },
  chatbotIsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  companyBranchesEnabled: (state, getters, rootState, rootGetters) => {
    const userRole = rootGetters['auth/userRole']
    if (userRole === ROLE_COMPANY_RIDER) { return false }
    return getRoleIsCompanyRole(userRole)
  },
  unlimitedLinksEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  googleRatingsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  getReviewsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  excelExportEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  statsPageEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  settingFacebookPixelEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  settingFacebookDomainValidationEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  settingGoogleTagManagerEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  settingGoogleAnalyticsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  unlimitedUsersEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  },
  showUpgradeYourPlan: (state, getters, rootState, rootGetters) => {
    // commented temporarily, requested by @morgan
    // if (rootGetters['flutter/panelIsEmbedInFlutter']) { return false }
    // if (rootGetters['flutter/panelIsInIosMobile']) { return false }
    if (rootGetters['auth/userRoleIsCompanyRider']) { return false }
    return rootGetters['auth/userHasCompanyRol']
  },
  editLandingStylesEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan || getters.companyHasElitePlan
  }
}

const actions = {
  setSubscriptions ({ commit }, { subscriptions }) {
    commit(SET_SUBSCRIPTIONS, { subscriptions })
  },
  setPartialSubscriptionData ({ commit }, { subscriptionId, data }) {
    commit(SET_PARTIAL_SUBSCRIPTION_DATA, { subscriptionId, data })
  }
}

const mutations = {
  [SET_SUBSCRIPTIONS] (state, { subscriptions }) {
    state.subscriptions = subscriptions
  },
  [SET_PARTIAL_SUBSCRIPTION_DATA] (state, { subscriptionId, data }) {
    const subscription = state.subscriptions.find(item => item.id === subscriptionId)

    if (!subscription) { return }

    for (const [key, value] of Object.entries(data)) {
      subscription[key] = value
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
