import {
  SET_NOTIFICATIONS_SETTINGS
} from '@/store/mutationTypes.js'
import { useRequest } from 'alova'
import notificationsMS from '@/services/notificationsMS'
import getDataToUpdateChatbotMessage from '@/helpers/getDataToUpdateChatbotMessage'

const state = () => ({
  notificationsSettings: null
})

const getters = {
  notificationsSettingsChatbotMessage: (state) => {
    if (!state.notificationsSettings) return null
    return state.notificationsSettings?.chatbot_settings?.messages || null
  }
}

const {
  send: sendGetSettings
} = useRequest(() => notificationsMS.getSettings(), { immediate: false })

const actions = {
  async getNotificationsSettings ({ commit, state, getters }) {
    try {
      const notificationsSettings = await sendGetSettings()
      commit(SET_NOTIFICATIONS_SETTINGS, notificationsSettings)
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = {
  [SET_NOTIFICATIONS_SETTINGS] (state, settings) {
    const settingsChatbotMessage = settings.chatbot_settings?.messages || null
    settings.chatbot_settings = getDataToUpdateChatbotMessage(settingsChatbotMessage)

    state.notificationsSettings = settings
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
